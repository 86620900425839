html, body, #root, .App, .wrapper {
  height: 100%;
  max-height: 100%;
  background: #eeeeee;

}

#log {
  z-index: -1;
  width: 100%;
  display: none;
}

.iteration {
  height: 100%;
  max-height: 100%;
  width:100%;
  max-width: 100%;
  cursor: pointer;
  padding: 0px;
  box-sizing: border-box;
  background: white;
  position: relative;
}

.iteration img {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  border: 10px solid #eeeeee;
  box-sizing: border-box;
}

.iteration div {
  transition: width, height .1 linear;
}

.iteration.playing {
}

.iteration.playing img {
  border: 10px solid #dddddd;
}
.header, .footer {
  text-align: center;
  font-size: 2vmin;
}

.header {
  grid-area: header;
  font-weight: 500;
  margin: 1vmin;
}

.header audio {
  display: none;
}

.App-link {
  color: #09d3ac;
}

.modal {
  display: grid;
  position: absolute;
  background: #22222299;
  backdrop-filter: blur(.5vmin);
  z-index: 1;
  top: 0px;
  height: 100%;
  left: 0px;
  width: 100%;
  grid-template-rows: 1fr 25vmin 1fr;
  grid-template-columns: 1fr 50vmin 1fr;
  grid-template-areas: 
    "modaltop modaltop modaltop" 
    "modalleft modal modalright" 
    "modalbottom modalbottom modalbottom" 
}

.modal-content {
  grid-area: modal;
  padding: 5vmin;
  display: flex;
}

.wrapper {
  display: grid;
  grid-gap: 0px;
  grid-template-rows: 1fr 20vmin 20vmin 20vmin 20vmin 1fr;
  grid-template-columns: .5fr 20vmin 20vmin 20vmin 20vmin .5fr;
  grid-template-areas: 
    "header header header header header header" 
    "gutter iteration00 iteration01 iteration02 iteration03 menu"
    "gutter iteration10 iteration11 iteration12 iteration13 menu"
    "gutter iteration20 iteration21 iteration22 iteration23 menu"
    "gutter iteration30 iteration31 iteration32 iteration33 menu"
    "footer footer footer footer footer footer"
}

.header {
  grid-area: header;
}

.footer {
  grid-area: footer;
}

.gutter {
  height: 100%;
  grid-area: gutter;
}

.menu {
  height: 100%;
  grid-area: menu;
}

.gallery-selection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 0vmin;
}

.gallery-selection div.button-wrapper {
  width:  23%;
  margin: .5vmin;
}

.gallery-selection div.button-wrapper span.youarehere {
  margin-top: 1vmin;
  display: inline-block;
}

button {
  width: 100%;
  font-family: 'Libre Franklin', sans-serif;
  font-size: 2vmin;
  cursor: pointer;
  padding: 1vmin;
  color: #a9a3a1;
  border: 1px solid #a9a3a1;
  background: #f7f6f5;
  display: inline-block;
  max-height: 100%;
  top: 0px;
  border-radius: 1vmin;
}
.start-button {
  width: 100%;
  color: black;
  border: 1px solid black;
  font-size: 3vmin;
  
}
.gallery-selection button.selected{
  background: white;
}

.gallery-selection button.selected.court {
  color: #d11d41;
  border: 1px solid #d11d41;
}

.gallery-selection button.selected.study {
  color: #800080;
  border: 1px solid #800080;
}

.gallery-selection button.selected.niche {
  color: #ed6821;
  border: 1px solid #ed6821;
}

.gallery-selection button.selected.bridge {
  color: #eca021;
  border: 1px solid #eca021;
}
